<template>
	<van-popup v-model:show="myVisible"
	           style="width: 75%"
	           teleport="#app">
		<div class="answer-popup flex column ">
			<h2>Q：{{ answerContext.title }}</h2>
			<div class="answer flex mt-3 fs-12">
				<span class="answer-sing">A：</span>
				<p class="answer-context fs-12">
					{{ answerContext.text }}
				</p>
			</div>
			<button class="mt-6  reflesh b button"
			        @click="myVisible=false">我知道了
			</button>
		</div>

	</van-popup>
</template>

<script>
// 风险规避指南
export default {
	name: 'TheShowAnswerPopup',
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		answerContext: {
			type: Object,
			require: true
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			show: false
		}
	},
	computed: {
		myVisible: {
			set(val) {
				this.$emit('update:modelValue', val)
			},
			get() {
				return this.modelValue
			}
		}
	}
}
</script>

<style scoped lang="scss">
.answer-popup {
	padding: px(20) px(15);
	border-radius: px(4);
	max-height: 40vh;

	.answer {
		flex: 1;
		align-items: baseline;
		overflow-y: auto;
		color: #433C3C;

		.answer-context {
				white-space: break-spaces;
		}
	}
}

.reflesh {
	margin: 0 auto;
	padding: px(6) px(35);
	font-size: px(14);
	font-weight: 500;
	color: #FF445E;
	border-radius: px(2);
	border: px(1) solid #FF445E;
}

</style>
